@import 'styles/variables.scss';

.title {
  color: $white-color;
  text-shadow: 2px 2px 2px $black-color, -2px -2px 2px $black-color,
    2px -2px 2px $black-color, -2px 2px 2px $black-color,
    2px 2px 2px $black-color;
  font-size: 15px;
  font-weight: 600;
}
