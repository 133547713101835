@import 'styles/mixins';
@import 'styles/variables';

.filters-icon {
  &-open {
    path {
      fill: $bright-product-color;
    }
  }
}

.filters-container {
  width: 300px;
  background-color: $dark-color;
  border-radius: 10px;
  border: 1px solid $light-text-color;
  z-index: 10;

  .parameters-wrapper {
    width: 100%;
    color: $base-text-color;
    .parameter-search {
      padding: 6px 0;
      border-bottom: 1px solid $ultrablack-color;
      .search-input {
        height: 20px;
        input {
          background-color: transparent;
        }
      }
    }
    .parameter-search-values {
      background-color: $ultrablack-color;
      padding-left: 12px;
      border-radius: 0 0 10px 10px;
      &:not(:empty) {
        padding-top: 6px;
        padding-bottom: 6px;
      }
      gap: 4px;
      max-height: 180px;
      overflow-y: scroll;
      div:hover {
        color: $bright-product-color;
      }
    }
    .parameter-header {
      padding: 6px 12px;
      border-bottom: 1px solid $ultrablack-color;
      &__no-border {
        border-bottom: none;
      }

      .datepicker-dropdown {
        top: 68px;
        right: 0;
        &_icon {
          &:hover {
            path {
              fill: $bright-product-color;
            }
          }
        }
      }
    }
    .chevron-wrapper {
      margin-left: auto;
      height: 16px;

      &__chevron {
        cursor: pointer;
        line-height: 0;
        transform: rotate(180deg);

        &_up {
          transform: rotate(0);
        }
      }
    }
  }
}
